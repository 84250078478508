import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { IMessage, RxStompState } from '@stomp/rx-stomp';
import { BarController, BarElement, CategoryScale, Chart, Colors, Legend, LinearScale, Tooltip } from 'chart.js';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { filter, map, Subject, take, takeUntil } from 'rxjs';

import primeLocaleNl from '../assets/primelocale';

import { SUITEVIEWPATH } from './core/core.routing';
import { HelpMenuService } from './help-menu.service';
import { MobileMenuService } from './mobile-menu.service';
import { ThemeFacade } from './repositories/theme/store/theme.facade';
import { IConfigurationItem, IFavourite, ThemeEntity } from './repositories/theme/store/theme.models';
import { SearchFacade } from './search/facades/search.facade';
import { UserDataFacade } from './userdata/facades/userdata.facade';
import { WalkthroughService } from './walkthrough.service';

import { environment } from '~/core/core.module';
import { rxStompServiceFactory } from '~/core/websockets/rx-stomp.factory';
import { suiteStompServiceFactory } from '~/core/websockets/suite-stomp.factory';
import { ImpersonateService } from '~/keycloak.impersonate';
import { RealmService } from '~/realm.service';
import { AnnouncementService } from '~/repositories/announcements/services/announcement.service';
import { AnnouncementFacade } from '~/repositories/announcements/store/announcement.facade';
import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';
import {
  CalculationModule,
  CalculationModuleService
} from '~/repositories/calculation-module/service/calculation-module.service';
import { MeetingPointer } from '~/repositories/meetingpointers/services/meetingpointer.service';
import {
  PrintListModule,
  PrintListModuleService
} from '~/repositories/print-list-module/service/print-list-module.service';
import { AsyncUserTask, UserTaskStatus, UserTaskType } from '~/repositories/userdata/store/userdata.models';
import { UserService } from '~/user.service';
import { VotingService } from '~/voting/voting.service';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Colors, Legend, Tooltip);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Green Valley Suite';
  public isLoggedIn = false;
  public user: KeycloakProfile;
  public starredMenuItems: MenuItem[];
  public userMenuItems = signal<MenuItem[]>([]);
  public configurationMenuItems: MenuItem[];
  public themeLoaded$ = this.themeFacade.loaded$;
  public theme$ = this.themeFacade.theme$;
  public footerInformation$ = this.themeFacade.footerInformation$;
  public exportsLength$ = this.userDataFacade.newExportsLength$;
  public walkthrough$ = this.walkthroughService.walkthroughForCurrentRoute$;
  public mobileMenuItems = this.mobileMenuService.mobileMenuItems;
  public mobileMenuOpen = this.mobileMenuService.mobileMenuOpen;
  public helpMenuItems$ = this.helpMenuService.helpMenuItems$;
  public allRolesActive$ = this.userDataFacade.allRolesActive$;
  public allRolesActiveLoading$ = this.userDataFacade.allRolesActiveLoading$;

  public showAnnouncementDialog = false;
  public announcements: AnnouncementEntity[] = [];
  public showNewAnnouncements = false;
  public newAnnouncements: AnnouncementEntity[] = [];

  public printListModule: PrintListModule;

  public calculationModule: CalculationModule;

  public meetingPointers: MeetingPointer[] = [];
  public showMeetingPointersDialog = false;

  public showLoginAsDialog = false;

  public isForbidden = signal<boolean>(false);
  public userHasRoles = signal<boolean>(true);

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private keycloakService: KeycloakService,
    private themeFacade: ThemeFacade,
    private userDataFacade: UserDataFacade,
    private router: Router,
    private translateService: TranslateService,
    private announcementFacade: AnnouncementFacade,
    private announcementService: AnnouncementService,
    private printListModuleService: PrintListModuleService,
    private calculationModuleService: CalculationModuleService,
    private http: HttpClient,
    private votingService: VotingService,
    private messageService: MessageService,
    private searchFacade: SearchFacade,
    private impersonateService: ImpersonateService,
    private realmService: RealmService,
    private userService: UserService,
    private primeNgConfig: PrimeNGConfig,
    private walkthroughService: WalkthroughService,
    private mobileMenuService: MobileMenuService,
    private helpMenuService: HelpMenuService,
    private localize: LocalizeRouterService
  ) {
    this.translateService.setDefaultLang('nl');
    this.primeNgConfig.setTranslation(primeLocaleNl.nl);
  }

  public ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        takeUntil(this.destroyed$)
      )
      .subscribe((event) => {
        const { state: eventState } = event as RoutesRecognized;
        this.toggleMobileMenu(false);
        this.walkthroughService.currentWalkthrough = eventState.root.firstChild?.data['key'] || null;
      });

    if (this.router.url === '/403') {
      this.isForbidden.set(true);
    } else {
      this.isForbidden.set(false);

      this.isLoggedIn = this.keycloakService.isLoggedIn();

      if (!this.isLoggedIn) {
        const isGvb = this.router.url === '/gvb';
        this.keycloakService.login({
          redirectUri: isGvb ? window.location.origin : window.location.href,
          idpHint: isGvb ? 'google' : null
        });
        return;
      }

      this.userService
        .checkUser()
        .pipe(take(1))
        .subscribe({
          error: () => {
            this.keycloakService.logout(window.location.origin + '/403');
          },
          next: (navigationItem) => {
            if (this.isRootUrl(this.router.url) && navigationItem.target === 'SUITEROUTE') {
              this.userHasRoles.set(false);
              this.router.navigateByUrl(SUITEVIEWPATH + navigationItem.url);
            }

            this.keycloakService.loadUserProfile().then((user) => {
              this.user = user;
              environment.USERNAME = user.username.toLowerCase();
              environment.USERID = user.id;
              environment.BEARER_TOKEN = this.keycloakService.getKeycloakInstance().token;
              //console.log('-----------------------------------');
              //console.log('ACCESS_TOKEN ');
              //console.log(this.keycloakService.getKeycloakInstance().token);
              //console.log('REFRESH_TOKEN ');
              //console.log(this.keycloakService.getKeycloakInstance().refreshToken);
              this.votingService.init();

              this.router.routerState.root.firstChild.data.pipe(take(1)).subscribe((data) => {
                this.walkthroughService.currentWalkthrough = (data as { key: string })?.key || null;
              });

              const rxStomp = rxStompServiceFactory();
              const stompConnected$ = rxStomp.connectionState$.pipe(
                filter((currentState: RxStompState) => {
                  return currentState === RxStompState.OPEN;
                }),
                takeUntil(this.destroyed$)
              );

              this.router.routerState.root.firstChild.data.pipe(take(1)).subscribe((data) => {
                this.walkthroughService.currentWalkthrough = (data as { key: string })?.key || null;
              });

              stompConnected$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                // subscribe to user specific channel when socket is open

                rxStomp
                  .createSubscription('/user/event/announcements')
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((msg: IMessage) => {
                    const announcements: AnnouncementEntity[] = JSON.parse(msg.body);
                    if (announcements.length > 0) {
                      this.newAnnouncements = announcements;
                      this.showNewAnnouncements = true;

                      // there's a change in announcements ... reload them
                      this.announcementFacade.initAnnouncements();
                    }
                  });

                rxStomp
                  .createSubscription('/user/event/printlist')
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((msg: IMessage) => {
                    const body = JSON.parse(msg.body) as unknown as PrintListModule;

                    this.printListModule = body;
                    this.mobileMenuService.setMobileMenuItemAtIndex(
                      {
                        ...this.mobileMenuService.mobileMenuItems[2],
                        badge: body.counter.toString(),
                        badgeStyleClass: 'theme-alternating-color theme-text-color'
                      },
                      2
                    );
                  });

                rxStomp
                  .createSubscription('/user/event/async-user-task')
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((msg: IMessage) => {
                    const userTask = JSON.parse(msg.body) as unknown as AsyncUserTask;

                    if (userTask.type == UserTaskType.SEARCH_EXPORT) {
                      if (userTask.status == UserTaskStatus.QUEUED) {
                        this.messageService.add({
                          severity: 'info',
                          detail: `${this.translateService.instant('search.export.generate.start', {
                            '0': userTask.name,
                            '1': `<a class='text-gray-800' href=${this.translateService.instant(
                              'ROUTES.userdata'
                            )}/${this.translateService.instant(
                              'ROUTES.generatedfiles'
                            )}>${this.translateService.instant('search.export.link.downloadpage')}</a>`
                          })}`
                        });
                      } else if (userTask.status == UserTaskStatus.COMPLETED) {
                        this.messageService.add({
                          severity: 'success',
                          detail: `${this.translateService.instant('search.export.generate.finished', {
                            '0': userTask.name,
                            '1': `<a class='underline cursor-pointer text-gray-800' id='downloadLink-${userTask.id}'
                        }>${this.translateService.instant('search.export.link.downloadnow')}</a>`
                          })}`
                        });
                        setTimeout(() => {
                          const el = document.getElementById(`downloadLink-${userTask.id}`);
                          el.addEventListener('click', () => this.handleDownloadTask(userTask));
                          setTimeout(() => {
                            el.removeEventListener('click', () => this.handleDownloadTask(userTask));
                          }, 3000);
                        }, 1);
                      } else if (userTask.status == UserTaskStatus.FAILED) {
                        this.messageService.add({
                          severity: 'error',
                          detail: this.translateService.instant('search.export.generate.failed', {
                            '0': userTask.name
                          })
                        });
                      }
                    }
                    this.userDataFacade.updateAsyncUserTask(userTask);
                  });
              });

              const suiteStomp = suiteStompServiceFactory();
              const suiteStompConnected$ = suiteStomp.connectionState$.pipe(
                filter((currentState: RxStompState) => {
                  return currentState === RxStompState.OPEN;
                }),
                takeUntil(this.destroyed$)
              );

              suiteStompConnected$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                suiteStomp
                  .createSubscription('/user/event/meeting/pointers')
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((msg: IMessage) => {
                    this.meetingPointers = JSON.parse(msg.body);

                    this.mobileMenuService.setMobileMenuItemAtIndex(
                      {
                        label: this.translateService.instant('current.meetings'),
                        command: () => {
                          this.openMeetingPointersDialog();
                          this.toggleMobileMenu(false);
                        },
                        badge: this.meetingPointers.length.toString(),
                        badgeStyleClass: 'theme-alternating-color theme-text-color'
                      },
                      0
                    );
                  });
              });

              this.userMenuItems.set([
                {
                  label: this.translateService.instant('preferences.menu.roles'),
                  icon: 'fa-regular fa-gear',
                  routerLink: `${SUITEVIEWPATH}/preferences/roles`,
                  target: '_self'
                },
                {
                  label: this.translateService.instant('preferences.menu.logout'),
                  icon: 'fa-regular fa-power-off',
                  command: () => this.logout()
                }
              ]);
              this.mobileMenuService.setMobileMenuItemAtIndex(
                {
                  label: `${user.firstName} ${
                    user.attributes['middleName'] ? ` ${user.attributes['middleName']}` : ''
                  } ${user.lastName}`,
                  icon: 'fa-solid fa-user',
                  items: this.userMenuItems()
                },
                6
              );
              // TODO: init themeFacade with user.organisationID
              const organisation = 123; // user.organisationID
              this.themeFacade.init(organisation);

              this.themeFacade.loadFavourites();
              this.themeFacade.loadHelpInformation();
              this.themeFacade.loadFooterInformation();
              this.themeFacade.loadConfigurationItems();

              this.userDataFacade.fetchAsyncUserTasks();
              this.userDataFacade.fetchAllRolesActive();

              this.searchFacade.fetchQueries();

              this.theme$
                .pipe(
                  filter((theme) => !!theme),
                  take(1),
                  map((theme: ThemeEntity) => {
                    const style = document.createElement('style');
                    style.innerHTML = `
                        .empty-state-icon {
                          color: ${theme.backgroundColor} !important;
                        }
                        .theme-background-color, .p-sidebar-header {
                          background-color: ${theme.backgroundColor} !important;
                        }
                        .theme-text-color {
                          color: ${theme.textColor} !important;
                        }
                        .theme-alternating-color {
                          background-color: ${theme.alternatingColor} !important;
                        }
                        .theme-border-color {
                          border-color: ${theme.alternatingColor} !important;
                        }
                        .p-progress-spinner .p-progress-spinner-circle {
                          stroke: ${theme.alternatingColor} !important;
                        }
                      `;
                    return document.head.appendChild(style);
                  })
                )
                .subscribe();

              this.themeFacade.favourites$
                .pipe(
                  filter((theme) => !!theme),
                  take(1),
                  map((favourites: IFavourite[]) => {
                    this.starredMenuItems = favourites.map((item) => {
                      return {
                        label: item.label,
                        url: item.allFavouritesUrl,
                        items: item.favouriteTypes.map((type) => {
                          return {
                            label: type.name,
                            url: type.url
                          };
                        })
                      };
                    });
                  })
                )
                .subscribe();

              this.helpMenuService.init();

              this.announcementFacade.initAnnouncements();

              this.announcementService
                .getNewAnnouncements()
                .pipe(takeUntil(this.destroyed$))
                .subscribe((newAnnouncements) => {
                  if (newAnnouncements.length > 0) {
                    this.newAnnouncements = newAnnouncements;
                    this.showNewAnnouncements = true;
                  }
                });

              this.printListModuleService
                .getPrintList()
                .pipe(takeUntil(this.destroyed$))
                .subscribe((printList) => {
                  this.printListModule = printList;

                  this.mobileMenuService.setMobileMenuItemAtIndex(
                    {
                      label: this.translateService.instant('information.printlist.title'),
                      badge: printList?.counter?.toString(),
                      badgeStyleClass: 'theme-alternating-color theme-text-color',
                      routerLink: [SUITEVIEWPATH, 'print']
                    },
                    2
                  );
                });

              this.calculationModuleService
                .getCalculationModule()
                .pipe(takeUntil(this.destroyed$))
                .subscribe((calculationModule) => {
                  if (calculationModule) {
                    this.calculationModule = calculationModule;
                    this.mobileMenuService.setMobileMenuItemAtIndex(
                      {
                        label: this.translateService.instant('information.calculation.title'),
                        routerLink: SUITEVIEWPATH + calculationModule.url
                      },
                      3
                    );
                  }
                });

              this.themeFacade.configurationItems$
                .pipe(
                  filter((configurationItems) => !!configurationItems?.length),
                  take(1),
                  map((configurationItems: IConfigurationItem[]) => {
                    this.configurationMenuItems = configurationItems.map((item) => {
                      if (item.routeType === 'FRONTENDROUTE') {
                        return {
                          label: item.name,
                          routerLink: this.translateService.instant(item.url),
                          target: item.target || '_self'
                        };
                      }
                      if (item.routeType === 'LOGIN_AS_MODAL') {
                        return {
                          label: item.name,
                          command: () => this.openLoginAsDialog()
                        };
                      }
                      // SUITEROUTE is default
                      return {
                        label: item.name,
                        routerLink: `${SUITEVIEWPATH}/${item.url}`,
                        target: item.target || '_self'
                      };
                    });
                    this.mobileMenuService.setMobileMenuItemAtIndex(
                      {
                        label: this.translateService.instant('information.configuration.title'),
                        items: this.configurationMenuItems,
                        icon: 'fa-regular fa-gear'
                      },
                      7
                    );
                  })
                )
                .subscribe();

              this.announcementFacade.allAnnouncements$.pipe(takeUntil(this.destroyed$)).subscribe((announcements) => {
                if (announcements.length) {
                  this.announcements = announcements;
                  this.mobileMenuService.setMobileMenuItemAtIndex(
                    {
                      label: this.translateService.instant('information.announcements.title'),
                      command: () => {
                        this.openAnnouncementDialog();
                        this.toggleMobileMenu(false);
                      },
                      badge: announcements.length.toString(),
                      badgeStyleClass: 'theme-alternating-color theme-text-color'
                    },
                    1
                  );
                }
              });

              this.mobileMenuService.setMobileMenuItemAtIndex(
                {
                  label: this.translateService.instant('userdata.generatedfiles.title'),
                  routerLink: this.localize.translateRoute('ROUTES.userdata/ROUTES.generatedfiles'),
                  badgeStyleClass: 'p-badge-danger'
                },
                4
              );

              this.userDataFacade.newExportsLength$.pipe(takeUntil(this.destroyed$)).subscribe((exportsLength) => {
                this.mobileMenuService.setMobileMenuItemAtIndex(
                  {
                    ...this.mobileMenuService.mobileMenuItems[4],
                    badge: exportsLength.toString(),
                    badgeStyleClass: 'theme-alternating-color theme-text-color'
                  },
                  4
                );
              });

              this.userDataFacade.allRolesActive$
                .pipe(
                  filter((allRolesActive) => !allRolesActive),
                  takeUntil(this.destroyed$)
                )
                .subscribe(() => {
                  if (!environment.USERNAME_AS) {
                    this.mobileMenuService.setMobileMenuItemAtIndex(
                      {
                        ...this.mobileMenuService.mobileMenuItems[6],
                        icon: 'fa-solid fa-user-xmark',
                        items: [...this.mobileMenuService.mobileMenuItems[6].items]
                      },
                      6
                    );
                  }
                });
            });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  handleSearch(text: string) {
    this.searchFacade.reset();
    localStorage.removeItem('search.current.state');

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([this.translateService.instant('ROUTES.search')], {
        queryParams: text && { query: text }
      })
    );
  }

  toggleMobileMenu(open: boolean) {
    this.mobileMenuService.mobileMenuOpen = open;
  }

  openAnnouncementDialog() {
    this.showAnnouncementDialog = true;
  }

  openMeetingPointersDialog() {
    this.showMeetingPointersDialog = true;
  }

  openLoginAsDialog() {
    this.showLoginAsDialog = true;
  }

  showRoles() {
    this.router.navigateByUrl(`${SUITEVIEWPATH}/preferences/roles`);
  }

  handleLoginAs(username: string) {
    environment.USERNAME_AS = username.toLowerCase();

    const label = `${this.translateService.instant('management.superadmin.loggedinas')} ${username}`;

    this.userMenuItems.update((items) => [
      {
        label
      },
      ...items
    ]);

    this.mobileMenuService.setMobileMenuItemAtIndex(
      {
        ...this.mobileMenuService.mobileMenuItems[6],
        icon: 'fa-solid fa-user-group',
        items: [
          {
            label
          },
          ...this.mobileMenuService.mobileMenuItems[6].items
        ]
      },
      6
    );

    this.toggleMobileMenu(false);

    const impRequest = this.impersonateService.impersonate(
      environment.USERNAME_AS,
      this.keycloakService,
      this.realmService
    );
    impRequest.pipe(take(1)).subscribe();
    this.router.navigateByUrl(`${SUITEVIEWPATH}/management/users/login/as/username/${username}`);
  }

  logout() {
    this.logoutOldBackend();
    environment.USERID = null;
    environment.USERNAME = null;
    environment.BEARER_TOKEN = null;
    this.keycloakService.logout(window.location.origin);
  }

  logoutOldBackend() {
    this.http
      .get(`${environment.OLD_BACKEND_URL}/sso/logout`)
      .pipe(take(1))
      .subscribe({
        error: (error) => {
          console.log('Error suite logout!');
          console.log(error);
        }
      });
  }

  markAnnouncementsViewed(announcementIds: string[]) {
    this.announcementFacade.markAnnouncementsViewed(announcementIds);
    this.showAnnouncementDialog = false;
  }

  markNewAnnouncementsViewed(announcementIds: string[]) {
    this.announcementFacade.markAnnouncementsViewed(announcementIds);
    this.showNewAnnouncements = false;
  }

  handleDownloadTask(task: AsyncUserTask) {
    this.http
      .get(`${environment.BACKEND_URL}/userdata/async-tasks/${task.id}/download`, {
        responseType: 'blob'
      })
      .pipe(take(1))
      .subscribe((blob) => {
        const blobUrl = URL.createObjectURL(blob);

        // temp link to download file
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = task.documentName;
        link.click();
        link.remove();

        this.userDataFacade.updateAsyncUserTask({ ...task, downloaded: true });
      });
  }

  handleCloseWalkthrough() {
    this.walkthroughService.setViewed();
  }

  isRootUrl(url: string): boolean {
    return url === '/' || url.startsWith('/#');
  }
}
